import React from "react";
import cardImg1 from "../assets/icons/cardImg1.png";
import cardImg2 from "../assets/icons/cardImg2.png";
import cardImg3 from "../assets/icons/cardImg3.png";

const Cards = () => {
  const cardData = [
    {
      id: 1,
      cardIcon: cardImg1,
      title: "Tailored for compliance needs ",
      details:
        "Whether you are working in a Fintech, a Crypto firm or a commercial bank, ChatKYC is designed to address the unique compliance challenges you face.",
    },
    {
      id: 2,
      cardIcon: cardImg2,
      title: "No Tech Expertise Required",
      details:
        "You don’t need to be a tech whizz to leverage ChatKYC’s AI powered insights. Its intuitive design has been developed by compliance professionals to help you address your compliance needs with ease and simplicity.",
    },

    {
      id: 3,
      cardIcon: cardImg3,
      title: "Digest Regulations",
      details:
        "Interpret and implement regulations easier than ever before. From MICAR to MIFID, from DORA to Dodd Frank, ChatKYC will be your regulatory assistant.",
    },
  ];
  return (
    <>
      {cardData.map((index) => (
        <div className="cardsContainer">
          <img src={index.cardIcon} alt="" />
          <div className="gradientDiv"></div>
          <h4 className="fw-semibold text-center text-capitalize">{index.title}</h4>
          <p className="text-large text-center fw-normal">{index.details}</p>
        </div>
      ))}
    </>
  );
};

export default Cards;
