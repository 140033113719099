import React from "react";
import Heading from "../../../shared/heading";
import Cards from "../../../shared/cards";
import gloww from "../../../assets/images/home/benefitGlow.webp";

const Benefits = () => {
  return (
    <>
      <div className="benfitSection">
        <img className="glow" src={gloww} alt="" />
        <div className="container">
        <Heading
          headingtext="A Transformative Approach to Compliance"
         
        />
        <div className="innerArea">
          <Cards />
        </div>
        </div>
    
      </div>
    </>
  );
};

export default Benefits;
