import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Buttoon from "../../buttoon";

const Money = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className="moneySection d-none ">
        <div className="container">
          <div className="inner d-none">
            <h1 className="fw-bold text-center">
            Make your life easier for only $7 a month
            </h1>
            {/* <p className="text-large text-center">
              We've made ChatKYC affordable so everyone from engineers to
              founders to Chief Product Officers can benefit from an AI PM.
            </p> */}
            <Link to="/pricing">
              <Buttoon
                className="btn btn-primary heroBtn"
                minWidth={197}
                minHeight={43}
                text="Check Out Our Pricing"
            
              ></Buttoon>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Money;
