import React, { useEffect } from "react";
import Navbar from "../../shared/navbar";
import Footer from "../../shared/footer";

const TermsUk = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />

      {/* Terms of Use Section */}
      <div className="container py-5">
        <div className="w-75 mx-auto mb-4 d-flex flex-column">
          <h1 className="text-center mb-3 lh-1 fs-2 fw-bold">
            Terms of Use (European Economic Area (EEA), Switzerland, or UK)
          </h1>
        </div>

        <h2 className="mb-2">1. Introduction</h2>
        <p className="mb-4 text-large">
          Welcome to ChatKYC, an AI Compliance Tool (hereinafter referred to as
          the “Tool”) created and owned by Oganiru Advisory Ltd, a company
          incorporated in the United Kingdom under company registration no:
          15427020.
        </p>
        <p className="mb-4 text-large">
          These Terms of Use (“Terms”) govern your access to and use of the
          Tool. By accessing or using the Tool, you agree to be bound by these
          Terms. If you do not agree to these Terms, please do not use the Tool.
        </p>

        <h2 className="mb-2">2. Definitions</h2>
        <ul className="mb-4 text-large">
          <li>
            <strong>Tool:</strong> The AI KYC Compliance Tool developed by
            Oganiru Advisory Ltd for addressing compliance-related questions,
            tasks, projects, or challenges.
          </li>
          <li>
            <strong>User:</strong> Any individual or entity accessing or using
            the Tool.
          </li>
          <li>
            <strong>Company:</strong> Oganiru Advisory Ltd including its
            affiliates and subsidiaries.
          </li>
          <li>
            <strong>Content:</strong> Any data, text, images, or other materials
            generated, uploaded, downloaded, or appearing on the Tool.
          </li>
        </ul>

        <h2 className="mb-2">3. Eligibility</h2>
        <p className="mb-4 text-large">
          By using the Tool, you represent and warrant that you are at least 18
          years of age and have the legal authority to enter into these Terms.
          If you are using the Tool on behalf of an organization, you represent
          and warrant that you have the authority to bind the organization to
          these Terms.
        </p>

        <h2 className="mb-2">4. User Responsibilities</h2>
        <ul className="mb-4 text-large">
          <li>
            <strong>Compliance with Laws:</strong> Users are responsible for
            ensuring that their use of the Tool complies with all applicable
            laws and regulations, including but not limited to data protection,
            privacy laws, and export control laws.
          </li>
          <li>
            <strong>Account Security:</strong> Users are responsible for
            maintaining the confidentiality of their account credentials and for
            all activities that occur under their account. Users agree to notify
            the Company immediately of any unauthorized use of their account or
            any other breach of security.
          </li>
          <li>
            <strong>Prohibited Activities:</strong>
            <ul className="list-unstyled ms-3">
              <li>• Using the Tool for any illegal or unauthorized purpose.</li>
              <li>
                • Interfering with or disrupting the integrity or performance of
                the Tool.
              </li>
              <li>
                • Attempting to gain unauthorized access to the Tool or its
                related systems or networks.
              </li>
              <li>• Transmitting any viruses, malware, or harmful code.</li>
              <li>
                • Misusing the Tool in a manner that could damage, disable,
                overburden, or impair the Tool or interfere with any other
                party’s use of the Tool.
              </li>
              <li>
                • Using the Tool to develop or enhance any competing product or
                service.
              </li>
            </ul>
          </li>
        </ul>

        <h2 className="mb-2">5. Intellectual Property</h2>
        <ul className="mb-4 text-large">
          <li>
            <strong>Ownership:</strong> The Tool, including its content,
            features, and functionality, is the exclusive property of Oganiru
            Advisory Ltd and its licensors. The Tool is protected by copyright,
            trademark, and other intellectual property laws.
          </li>
          <li>
            <strong>Limited License:</strong> Subject to these Terms, the
            Company grants Users a non-exclusive, non-transferable, limited
            license to access and use the Tool for their personal or
            organizational compliance needs.
          </li>
          <li>
            <strong>Feedback:</strong> Users may provide feedback, suggestions,
            or ideas about the Tool (“Feedback”). Users agree that all Feedback
            is the exclusive property of the Company and may be used by the
            Company without restriction or obligation to the User.
          </li>
        </ul>

        <h2 className="mb-2">6. Data Privacy</h2>
        <ul className="mb-4 text-large">
          <li>
            <strong>Collection and Use of Data:</strong> The Company collects
            and uses data in accordance with its Privacy Policy, which is
            incorporated by reference into these Terms. By using the Tool, Users
            consent to the collection and use of their data as described in the
            Privacy Policy.
          </li>
          <li>
            <strong>Data Security:</strong> The Company implements appropriate
            technical and organizational measures to protect User data from
            unauthorized access, use, or disclosure.
          </li>
          <li>
            <strong>Data Use and Processing:</strong> Users agree that the
            Company may use data input into the Tool to improve and enhance the
            Tool’s capabilities and for other development purposes.
          </li>
        </ul>

        <h2 className="mb-2">7. Disclaimers</h2>
        <ul className="mb-4 text-large">
          <li>
            <strong>No Warranty:</strong> The Tool is provided “as is” and “as
            available” without warranties of any kind, either express or
            implied. The Company does not warrant that the Tool will be
            uninterrupted, error-free, or free of harmful components.
          </li>
          <li>
            <strong>Limitation of Liability:</strong> To the fullest extent
            permitted by law, the Company shall not be liable for any indirect,
            incidental, special, consequential, or punitive damages arising out
            of or related to the use of or inability to use the Tool.
          </li>
        </ul>

        <h2 className="mb-2">8. Indemnification</h2>
        <p className="mb-4 text-large">
          Users agree to indemnify and hold harmless the Company, its
          affiliates, and their respective officers, directors, employees, and
          agents from and against any claims, liabilities, damages, losses, and
          expenses, including reasonable attorneys’ fees, arising out of or
          related to their use of the Tool or violation of these Terms.
        </p>

        <h2 className="mb-2">9. Termination</h2>
        <p className="mb-4 text-large">
          The Company reserves the right to suspend or terminate User access to
          the Tool at any time, with or without notice, for any reason,
          including violation of these Terms.
        </p>

        <h2 className="mb-2">10. Governing Law and Jurisdiction</h2>
        <p className="mb-4 text-large">
          These Terms shall be governed by and construed in accordance with the
          laws of the jurisdiction within which you use the Tool. Any disputes
          arising out of or related to these Terms shall be subject to the
          exclusive jurisdiction of the courts of the jurisdiction within which
          you are based.
        </p>

        <h2 className="mb-2">11. Changes to Terms</h2>
        <p className="mb-4 text-large">
          The Company reserves the right to modify these Terms at any time.
          Users will be notified of any changes via email or through the Tool.
          Continued use of the Tool following any changes constitutes acceptance
          of the revised Terms.
        </p>

        <h2 className="mb-2">12. Dispute Resolution</h2>
        <ul className="mb-4 text-large">
          <li>
            <strong>Informal Resolution:</strong> Before filing a formal claim,
            Users agree to attempt to resolve any dispute informally by
            contacting the Company.
          </li>
          <li>
            <strong>Arbitration:</strong> Any unresolved disputes will be
            settled by binding arbitration conducted by a neutral arbitrator.
          </li>
          <li>
            <strong>Class Action Waiver:</strong> Users agree to resolve
            disputes only on an individual basis and waive any right to bring
            claims as part of a class action.
          </li>
        </ul>

        <h2 className="mb-2">13. Severability</h2>
        <p className="mb-4 text-large">
          If any provision of these Terms is held to be invalid or
          unenforceable, such provision shall be struck, and the remaining
          provisions shall remain in full force and effect.
        </p>

        <h2 className="mb-2">14. Contact Information</h2>
        <p className="mb-4  text-large">
          For questions or concerns regarding these Terms, please contact us at{" "}
          <a href="mailto:info@chatkyc.ai">info@chatkyc.ai</a>.
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default TermsUk;
