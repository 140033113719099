import React, { useState } from "react";
import footerlogo from "../assets/images/home/footerLogo.svg";
import Input from "./input";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Buttoon from "../components/buttoon";
import { FaLinkedinIn } from "react-icons/fa";
// import youtube from "../assets/icons/youtubeIcon.svg";
// import insta from "../assets/icons/instaIcon.svg";
import { RiTwitterXLine } from "react-icons/ri";
import { scroller } from "react-scroll";

const Footer = () => {
  const [email, setemail] = useState("");
  const navigate = useNavigate();

  const navigateToHomeSection = (section, offset = 0) => {
    navigate("/");
    setTimeout(() => {
      scroller.scrollTo(section, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: offset,
      });
    }, 100);
  };
  return (
    <>
      <div className="footerSection">
        <div className="container">
          <div className="inner">
            <div className="leftArea">
              <img
                className="cursor-pointer"
                onClick={() => navigate("/")}
                src={footerlogo}
                alt=""
              />
            </div>
            <div className="rightArea">
              <p className="text-small fw-normal mb-3 text-left">
                Sign up to get update on ChatKyc and new features
              </p>
              <div className="inputDiv v-center justify-content-end gap-3">
                <Input
                  wrapperClass=" rounded   w-75 "
                  placeholder="your@email.com"
                  labelClass="bg-dangers small medium bg-transparent text-black-50"
                  inputClass="fw-medium text-black "
                  value={email}
                  height="43px"
                  type="email"
                  className="ftrEmail input-fieldd "
                  onChange={(e) => setemail(e.target.value)}
                />

                <Link to="/">
                  <Buttoon
                    className="btn btn-primary heroBtn"
                    minWidth={104}
                    minHeight={43}
                    text="Send"
                  ></Buttoon>
                </Link>
              </div>
              <div className="w-100 socialIcons v-center justify-content-end gap-2">
                <FaLinkedinIn className="cursor-pointer" />
                <RiTwitterXLine className="cursor-pointer" />
                {/* <img className="cursor-pointer" src={youtube} alt="" />
                <img className="cursor-pointer" src={insta} alt="" /> */}
              </div>
            </div>
          </div>
          <div className="bottomSection">
            <div className="leftBtmSection">
              {/* <ul className="text-small fw-medium v-center">
                <NavLink className="text-black cursor-pointer">
                  <li>PRODUCT</li>
                </NavLink>
                <a className="text-black cursor-pointer">
                  <li
                    onClick={() => navigateToHomeSection("Testimonials", -100)}
                  >
                    TESTIMONIAL
                  </li>
                </a>

                <a className="text-black cursor-pointer">
                  <li onClick={() => navigateToHomeSection("feature", -100)}>
                    FEATURES
                  </li>
                </a>

                <NavLink className="text-black cursor-pointer" to="/pricing">
                  <li>PRICING</li>
                </NavLink>

                <a className="text-black cursor-pointer">
                  <li onClick={() => navigateToHomeSection("feature", -100)}>
                    FAQ's
                  </li>
                </a>
              </ul> */}
            </div>
            <div className="rightBtmSection d-flex flex-column flex-md-row justify-content-between align-items-center text-center text-md-start gap-3">
  {/* Links Section */}
  <div className="d-flex flex-column flex-md-row gap-3">
    <NavLink
      to="/cookie-policy"
      className="policy-link text-small  text-decoration-none"
    >
      Cookie Policy
    </NavLink>
    <NavLink
      to="/terms-conditions-us"
      className="policy-link text-small  text-decoration-none"
    >
      Terms & Conditions (US and rest of world)
    </NavLink>
    <NavLink
      to="/terms-conditions-uk"
      className="policy-link text-small  text-decoration-none"
    >
      Terms & Conditions (EEA , CH , UK)
    </NavLink>
  </div>

  {/* Copyright Section */}
  <p className="text-small fw-medium m-0">
    Copyright © 2024 • Chat KYC.
  </p>
</div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
