import React, { useState } from "react";
import eyeOpen from "../assets/icons/eye-opeen.png";
import eyeClose from "../assets/icons/eye-close.svg";

const Input = ({
  type,
  wrapperClass,
  inputClass,
  labelClass,
  onChange,
  name,
  id,
  value,
  placeholder,
  icon,
  maxWidth,
  maxHeight,
  minHeight,
  minWidth,
  height,
  width,
  maxLength,
  error,
  disabled = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputStyle = {
    height: height,
    minHeight: minHeight,
    maxHeight: maxHeight,
    width: width,
    minWidth: minWidth,
    maxWidth: maxWidth,
    labelClass: labelClass,
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const getInputType = () => {
    if (type === "password") {
      return showPassword ? "text" : "password";
    }
    return type;
  };

  const getInputIcon = () => {
    if (type === "password") {
      return showPassword ? eyeOpen : eyeClose;
    }
    return icon;
  };

  return (
    <div
      className={`input-wrapper ${error ? "error" : ""} ${wrapperClass}`}
      style={inputStyle}
    >
      <input
        autoComplete="off"
        type={getInputType()}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className={inputClass}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
      />
      <label className={`text-capitalize ${labelClass}`}>{placeholder}</label>
      {icon && (
        <img
          src={getInputIcon()} // Updated to use corrected function
          alt="icon"
          className="cursor-pointer imgAbsolute"
          onClick={togglePassword}
        />
      )}
    </div>
  );
};

export default Input;
