// Sidebar.jsx
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Drawer, message, Modal , Spin} from "antd";
import moment from "moment";
import finalLogo from "../assets/icons/kycLogo.svg";
import Buttoon from "./buttoon";
import btnIcon from "../assets/icons/btnIcon.svg";
import ThreeDots from "../shared/threeDots";
import checkUserPlan from "../middleware/planServices";
import PricingUpgrade from "./pricing/pricingUpgarde";
import { useDashboardContext } from "./ContextData";

const Sidebar = ({ isDrawerOpen, toggleDrawer }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [planData, setPlanData] = useState(null);
  const [loadingPlan, setLoadingPlan] = useState(true);



  const {
    userDocument,
    handleDeleteDocument,
    UserData,
    handleNewChatClick,
    CurrentNewChatId,
    handleDeleteChat,
    handleRenameChat,
    handleRenameDocument,
  } = useDashboardContext();

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 1025);
    };
    window.addEventListener("resize", checkIfMobile);
    checkIfMobile();

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const contextHandleNewChatClick = async () => {
    try {
      await handleNewChatClick();
    } catch (error) {
      if (error.status === 403) {
        setIsModalOpen(true)
      } else {
        console.log("Error in API")
      }
    }
  };

  useEffect(() => {
    const fetchPlanData = async () => {
      try {
        const data = await checkUserPlan();
        setPlanData(data.chatUsed);
      } catch (error) {
        console.error("Error fetching user plan:", error.message);
        setPlanData(error.chatUsed);

      } finally {
        setLoadingPlan(false);
      }
    };

    fetchPlanData();
  }, []);

  const today = moment().startOf("day");
  const todayChats = UserData?.filter((chat) =>
    moment(chat.created_at).isSame(today, "day")
  );
  const yesterdayChats = UserData?.filter((chat) =>
    moment(chat.created_at).isBefore(today, "day")
  );
  const todayDocument = userDocument?.filter((doc) =>
    moment(doc.created_at).isSame(today, "day")
  );
  const yesterdayDocument = userDocument?.filter((doc) =>
    moment(doc.created_at).isBefore(today, "day")
  );

  return (
    <>
      {/* Desktop Sidebar */}
      {!isMobile && (
        <div className="sidebar-main-container d-flex flex-column">
          <div className="scrollable">
            <div className="sidebar-logo-container d-flex justify-content-start w-100">
              <Link to="/">
                <img src={finalLogo} alt="logo" className="logo" />
              </Link>
            </div>

            <Buttoon
              className="btn btn-primary w-100 newChatIcon d-flex v-center"
              minWidth={208}
              minHeight={39}
              img={btnIcon}
              text="New Chat"
              imgClass="newChatIcon"
              onClick={contextHandleNewChatClick}
            />

            {planData && planData.status === 200 ? (
                  <div
                    style={{ width: "100%" }}
                    onClick={showModal}
                    className="d-flex flex-column gap-1 pricePopup mt-3 cursor-pointer"
                  >
                    <p style={{ fontSize: "12px" }}>
                      User Used the Paid Plan. If needed, upgrade.
                    </p>
                    <div className="coloredDiv w-100"></div>
                    <p style={{ fontSize: "12px" }} className="text-primary">
                      Upgrade for less than $7 / month
                    </p>
                  </div>
                ) : (
                  <div
                    style={{ width: "100%" }}
                    onClick={showModal}
                    className="d-flex flex-column gap-1 pricePopup mt-3 cursor-pointer"
                  >
                    <p style={{ fontSize: "12px" }}>
                      You have used the Free {planData} Chats IDs
                    </p>
                    <div className="coloredDiv w-100"></div>
                    <p style={{ fontSize: "12px" }} className="text-primary">
                      Upgrade for less than $7 / month
                    </p>
                  </div>
                )}


            <div className="sidebar-options-container d-flex flex-column space-between">
              <div>
                {/* My Chats Section */}
                <div className="myChatBox">
                  <p className="text-small fw-bold text-left">My Chats</p>
                  <hr />
                  <p className="todayClr text-left text-small fw-normal my-2">
                    Today's
                  </p>
                  {todayChats?.map((chat) => (
                    <div
                      key={chat.chat_id}
                      className="mainList d-flex justify-content-between v-center"
                    >
                      <div className="leftArea d-flex gap-2 ">
                        <NavLink
                          to={`/dashboard/${chat.chat_id}`}
                          className="text-black d-flex gap-2"
                        >
                          <Icon
                            icon="circum:chat-2"
                            width="20"
                            height="20"
                            style={{ color: "gray" }}
                            className="sideIconss"
                          />

                          <span className="text-small fw-medium summary">
                            {chat.name}
                          </span>
                        </NavLink>
                      </div>
                      <ThreeDots
                        chatId={chat.chat_id}
                        handleDeleteChat={handleDeleteChat}
                        handleRenameChat={handleRenameChat}
                      />
                    </div>
                  ))}

                  <p className="todayClr text-left text-small fw-normal my-2">
                    Previous Chats
                  </p>
                  {yesterdayChats?.map((chat) => (
                    <div
                      key={chat.chat_id}
                      className="mainList d-flex justify-content-between v-center"
                    >
                      <div className="leftArea d-flex gap-2 ">
                        <NavLink
                          to={`/dashboard/${chat.chat_id}`}
                          className="text-black d-flex gap-2"
                        >
                          <Icon
                            icon="circum:chat-2"
                            width="20"
                            height="20"
                            style={{ color: "gray" }}
                            className="sideIconss"
                          />

                          <span className="text-small fw-medium">
                            {chat.name}
                          </span>
                        </NavLink>
                      </div>
                      <ThreeDots
                        chatId={chat.chat_id}
                        handleDeleteChat={handleDeleteChat}
                        handleRenameChat={handleRenameChat}
                      />
                    </div>
                  ))}
                </div>

                {/* My Files Section */}
                <div className="myChatBox">
                  <p className="text-small fw-bold text-left">My Files</p>
                  <hr />
                  <p className="todayClr text-left text-small fw-normal my-2">
                    Today's
                  </p>
                  {todayDocument
                    .filter((doc) => doc.saved_document_url !== undefined)
                    .map((doc) => (
                      <div
                        key={doc.chat_id}
                        className="mainList d-flex justify-content-between v-center"
                      >
                        <NavLink
                          to={`/dashboard/file/${doc.chat_id}`}
                          state={{
                            savedDocument: doc.saved_document_url,
                            chatId: doc.chat_id,
                            chatName: doc.name,
                          }}
                          className="text-black"
                        >
                          <div className="leftArea d-flex gap-2">
                            <Icon
                              icon="mdi:paper-edit-outline"
                              width="20"
                              height="20"
                              style={{ color: "gray" }}
                              className="sideIconss"
                            />
                            <span className="text-small fw-medium">
                              {doc.name}
                            </span>
                          </div>
                        </NavLink>
                        <ThreeDots
                          chatId={doc.chat_id}
                          documentId={doc.chat_id}
                          handleDeleteDocument={handleDeleteDocument}
                          handleRenameDocument={handleRenameDocument}
                        />
                      </div>
                    ))}

                  <p className="todayClr text-left text-small fw-normal my-2">
                  previous files
                  </p>
                  {yesterdayDocument
                    .filter((doc) => doc.saved_document_url !== undefined)
                    .map((doc) => (
                      <div
                        key={doc.chat_id}
                        className="mainList d-flex justify-content-between v-center"
                      >
                        <NavLink
                          to={`/dashboard/file/${doc.chat_id}`}
                          state={{
                            savedDocument: doc.saved_document_url,
                            chatId: doc.chat_id,
                            chatName: doc.name,
                          }}
                          className="text-black"
                        >
                          <div className="leftArea d-flex gap-2">
                            <Icon
                              icon="mdi:paper-edit-outline"
                              width="20"
                              height="20"
                              style={{ color: "gray" }}
                              className="sideIconss"
                            />
                            <span className="text-small fw-medium">
                              {doc.name}
                            </span>
                          </div>
                        </NavLink>
                        <ThreeDots
                          chatId={doc.chat_id}
                          documentId={doc.chat_id}
                          handleDeleteDocument={handleDeleteDocument}
                          handleRenameDocument={handleRenameDocument}
                        />
                      </div>
                    ))}
                </div>
              </div>

              {/* Account Settings */}
              <NavLink
                className="accLinkColr text-black"
                to="/settings/profile"
                onClick={toggleDrawer}
                style={{ width: "fit-content" }}
              >
                <div style={{ width: "auto" }} className="accSetting py-3">
                  <div
                    className="v-center gap-2 bottom-link"
                    style={{ width: "fit-content" }}
                  >
                    <Icon
                      icon="ion:settings-outline"
                      width="24"
                      height="24"
                      style={{ color: "#0085FF" }}
                    />
                    <span className="accText fw-bold text-small">
                      Account Settings
                    </span>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      )}

      {/* Mobile Sidebar */}
      {isMobile && (
        <div className="mobile-sidebar">
          <Drawer
            placement="left"
            closable={false}
            onClose={toggleDrawer}
            open={isDrawerOpen}
            width={300}
            className="mobile-menu-drawer"
          >
            <div
              className="sidebar-container d-flex flex-column"
              style={{ overflowY: "auto", height: "100%" }}
            >
              <div className="scrollable">
                <div className="sidebar-logo-container mb-2">
                  <Link to="/">
                    <img src={finalLogo} alt="logo" className="logo" />
                  </Link>
                </div>

                <Buttoon
                  className="btn btn-primary w-100 newChatIcon d-flex v-center"
                  minWidth={208}
                  minHeight={39}
                  img={btnIcon}
                  text="New Chat"
                  imgClass="newChatIcon"
                  onClick={() => {
                    contextHandleNewChatClick();
                    toggleDrawer();
                  }}
                />

                <div
                  onClick={() => {
                    showModal();
                    toggleDrawer();
                  }}
                  className="d-flex flex-column gap-1 pricePopup my-3 cursor-pointer"
                >
                  <p style={{ fontSize: "12px" }}>
                    You have used 3 of 3 free chats.
                  </p>
                  <div
                    style={{
                      width: "100%",
                      height: "8px",
                      backgroundImage:
                        "linear-gradient(92deg, #009EFF 0.8%, #9360FF 98.13%)",
                      borderRadius: "5px",
                    }}
                  />
                  <p style={{ fontSize: "12px" }} className="text-primary">
                    Upgrade for less than $7 / month
                  </p>
                </div>

                <div className="sidebar-options-container d-flex flex-column space-between">
                  <div>
                    {/* My Chats Section */}
                    <div className="myChatBox">
                      <p className="text-small fw-bold text-left">My Chats</p>
                      <hr />
                      <p className="todayClr text-left text-small fw-normal my-2">
                        Today's
                      </p>
                      {todayChats?.map((chat) => (
                        <div
                          key={chat.chat_id}
                          className="mainList d-flex justify-content-between v-center"
                        >
                          <div className="leftArea d-flex gap-2 ">
                            <NavLink
                              to={`/dashboard/${chat.chat_id}`}
                              className="text-black d-flex gap-2"
                              onClick={toggleDrawer}
                            >
                              <Icon
                                icon="circum:chat-2"
                                width="20"
                                height="20"
                                style={{ color: "gray" }}
                                className="sideIconss"
                              />

                              <span className="text-small fw-medium">
                                {chat.name}
                              </span>
                            </NavLink>
                          </div>
                          <ThreeDots
                            chatId={chat.chat_id}
                            handleDeleteChat={handleDeleteChat}
                            handleRenameChat={handleRenameChat}
                          />
                        </div>
                      ))}

                      <p className="todayClr text-left text-small fw-normal my-2">
                        Yesterday's
                      </p>
                      {yesterdayChats?.map((chat) => (
                        <div
                          key={chat.chat_id}
                          className="mainList d-flex justify-content-between v-center"
                        >
                          <div className="leftArea d-flex gap-2 ">
                            <NavLink
                              to={`/dashboard/${chat.chat_id}`}
                              className="text-black d-flex gap-2"
                              onClick={toggleDrawer}
                            >
                              <Icon
                                icon="circum:chat-2"
                                width="20"
                                height="20"
                                style={{ color: "gray" }}
                                className="sideIconss"
                              />

                              <span className="text-small fw-medium">
                                {chat.name}
                              </span>
                            </NavLink>
                          </div>
                          <ThreeDots
                            chatId={chat.chat_id}
                            handleDeleteChat={handleDeleteChat}
                            handleRenameChat={handleRenameChat}
                          />
                        </div>
                      ))}
                    </div>

                    {/* My Files Section */}
                    <div className="myChatBox">
                      <p className="text-small fw-bold text-left">My Files</p>
                      <hr />
                      <p className="todayClr text-left text-small fw-normal my-2">
                        Today's
                      </p>
                      {todayDocument
                        .filter((doc) => doc.saved_document_url !== undefined)
                        .map((doc) => (
                          <div
                            key={doc.chat_id}
                            className="mainList d-flex justify-content-between v-center"
                          >
                            <NavLink
                              to={`/dashboard/file/${doc.chat_id}`}
                              state={{
                                savedDocument: doc.saved_document_url,
                                chatId: doc.chat_id,
                                chatName: doc.name,
                              }}
                              className="text-black"
                              onClick={toggleDrawer}
                            >
                              <div className="leftArea d-flex gap-2">
                                <Icon
                                  icon="mdi:paper-edit-outline"
                                  width="20"
                                  height="20"
                                  style={{ color: "gray" }}
                                  className="sideIconss"
                                />
                                <span className="text-small fw-medium">
                                  {doc.name}
                                </span>
                              </div>
                            </NavLink>
                            <ThreeDots
                              chatId={doc.chat_id}
                              documentId={doc.chat_id}
                              handleDeleteDocument={handleDeleteDocument}
                              handleRenameDocument={handleRenameDocument}
                            />
                          </div>
                        ))}

                      <p className="todayClr text-left text-small fw-normal my-2">
                      previous files
                      </p>
                      {yesterdayDocument
                        .filter((doc) => doc.saved_document_url !== undefined)
                        .map((doc) => (
                          <div
                            key={doc.chat_id}
                            className="mainList d-flex justify-content-between v-center"
                          >
                            <NavLink
                              to={`/dashboard/file/${doc.chat_id}`}
                              state={{
                                savedDocument: doc.saved_document_url,
                                chatId: doc.chat_id,
                                chatName: doc.name,
                              }}
                              className="text-black"
                              onClick={toggleDrawer}
                            >
                              <div className="leftArea d-flex gap-2">
                                <Icon
                                  icon="mdi:paper-edit-outline"
                                  width="20"
                                  height="20"
                                  style={{ color: "gray" }}
                                  className="sideIconss"
                                />
                                <span className="text-small fw-medium">
                                  {doc.name}
                                </span>
                              </div>
                            </NavLink>
                            <ThreeDots
                              chatId={doc.chat_id}
                              documentId={doc.chat_id}
                              handleDeleteDocument={handleDeleteDocument}
                              handleRenameDocument={handleRenameDocument}
                            />
                          </div>
                        ))}
                    </div>
                  </div>

                  {/* Account Settings */}
                  <NavLink
                    className="accLinkColr text-black"
                    to="/settings/profile"
                    onClick={toggleDrawer}
                    style={{ width: "fit-content" }}
                  >
                    <div style={{ width: "auto" }} className="accSetting py-3">
                      <div
                        className="v-center gap-2 bottom-link"
                        style={{ width: "fit-content" }}
                      >
                        <Icon
                          icon="ion:settings-outline"
                          width="24"
                          height="24"
                          style={{ color: "#0085FF" }}
                        />
                        <span className="accText fw-bold text-small">
                          Account Settings
                        </span>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </Drawer>
        </div>
      )}

      <Modal
        open={isModalOpen}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={null}
        width={837}
        centered={true}
      >
        <PricingUpgrade />
      </Modal>
    </>
  );
};

export default Sidebar;
