import React from "react";
import Heading from "../../../shared/heading";
import feature1icon from "../../../assets/icons/copyy.svg";
import simplePage from "../../../assets/icons/simpleNote.svg";
import riskPic from "../../../assets/icons/risk.svg";
import compilance from "../../../assets/icons/featureIcon.svg";
import featureRightPic from "../../../assets/images/home/feature.png";
import { GoDotFill } from "react-icons/go";

const Features = () => {
  return (
    <>
      <div name="feature" className="featureSection">
        <Heading
          headingtext="Revolutionize your approach to Compliance"
          headingClass="text-capitalize"
        />
        <div className="container">
          <div className="innerArea">
            <div className="leftArea">
              <li>
                <img
                  className="featureIcons"
                  src={simplePage}
                  alt="Simplified Regulation Summarization Icon"
                />
                <div className="d-flev flex-column gap-4">
                  <h4 className="fw-semibold">
                    Simplified Regulation Summaries
                  </h4>
                  <p className="fw-normal text-large">
                    Transform dense regulatory texts into clear, concise
                    summaries with our cutting-edge AI. Stay compliant without
                    getting buried in paperwork.
                  </p>
                </div>
              </li>

              <li>
                <img
                  className="featureIcons"
                  src={riskPic}
                  alt="Effortless Risk Assessments Icon"
                />
                <div className="d-flev flex-column gap-4">
                  <h4 className="fw-semibold">Effortless Risk Assessments</h4>
                  <p className="fw-normal  text-large">
                    Proactively assess and manage regulatory risks with
                    customized tools designed for the non-tech savvy. Secure
                    your fintech operations with confidence.
                  </p>
                </div>
              </li>

              <li>
                <img
                  className="featureIcons"
                  src={compilance}
                  alt="Streamlined Compliance Reporting Icon"
                />
                <div className="d-flev flex-column gap-4">
                  <h4 className="fw-semibold">
                    Streamlined Compliance Reporting
                  </h4>
                  <p className="fw-normal  text-large  ">
                    Generate audit-ready reports with ease. ChatKYC automates
                    the grunt work, letting you focus on strategic compliance
                    planning.
                  </p>
                </div>
              </li>

              <div className="another-features ">
                <ul className="list-disc"   style={{
      listStyleType: "disc", // Ensures the bullets  are set to 'disc'
    }}>
              <div className="another-features">
  <ul className="list-unstyled ms-3">
    <li className="disc-li d-flex align-items-center gap-2 mb-2">
      <GoDotFill className="text-primary dot" /> Document Comparison
    </li>
    <li className="disc-li d-flex align-items-center gap-2 mb-2">
      <GoDotFill className="text-primary dot" /> Document Analysis
    </li>
    <li className="disc-li d-flex align-items-center gap-2 mb-2">
      <GoDotFill className="text-primary dot" /> Gap Analyses
    </li>
    <li className="disc-li d-flex align-items-center gap-2 mb-2">
      <GoDotFill className="text-primary dot" /> Policy Drafting
    </li>
    <li className="disc-li d-flex align-items-center gap-2 mb-2">
      <GoDotFill className="text-primary dot" /> Template Generation
    </li>
    <li className="disc-li d-flex align-items-center gap-2 mb-2">
      <GoDotFill className="text-primary dot" /> Risk & Compliance Strategy Development
    </li>
    <li className="disc-li d-flex align-items-center gap-2 mb-2">
      <GoDotFill className="text-primary dot" /> Risk & Compliance Roadmap Generation
    </li>
  </ul>
</div>
                </ul>
              </div>
            </div>

            <div className="rightArea">
              <img src={featureRightPic} alt="Feature Illustration" />
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
