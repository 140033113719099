import axios from 'axios';

/**
 * @returns {Promise<Object>} 
 */
const checkUserPlan = async () => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/payment/checkUserPlan`,
      {}, 
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`, 
        },
      }
    );
    return response; 
  } catch (error) {
    if (error.response) {
      const { status, data ,chatUsed } = error.response;
      throw { status, message: data.message , chatUsed:data.chatUsed || 'An error occurred.' };
    } else if (error.request) {
      throw { status: null, message: 'No response from server. Please try again.' };
    } else {
      throw { status: null, message: 'Request setup failed. Please try again.' };
    }
  }
};

export default checkUserPlan;
