import React, { useState } from "react";
import PricingCard from "../../shared/pricingCard";
import UpgradePlanModal from "../../dashboard/modals/upgradePlans";

const PricingUpgrade = () => {
  const [active, setActive] = useState("Monthly");
  const CardData = [
    {
      id: 2,
      dollarYear: 35,
      dollarMonth: 7,
      badge: "Basic",
      year: "/Year",
      month: "/Monthly",
      priceId: process.env.REACT_APP_BASIC_PLAN_PRICE,
      features: [
        "Regulation query, including references.", 
"Basic document drafting",
"Summarizing texts/documents.",
"Basic document enhancement (e.g., grammar and style editing of short texts).",
"2 document uploads per day.",
"Customer support via email with a 24-hour response time.",
"Basic risk and compliance strategic insights."    
      ],
    },
    {
      id: 3,
      dollarYear: 100,
      dollarMonth: 12,
      badge: "Pro",
      year: "/Year",
      month: "/Annually",
      priceId: process.env.REACT_APP_PRO_PLAN_PRICE,
      features: [
        "Includes all features of the Basic plan.",
         "Unlimited chat and document interactions and uploads.",
         "Risk assessment drafting.",
         "Policy document drafting.",
         "Advanced document enhancement and editing capabilities, including comprehensive editing for lengthy documents.",
         "Document comparison and analysis.",
         "Access to text to table prompting (table generation).",
         "In-depth risk and compliance strategic insights.",
         "Priority customer support, with a 4-hour response time.",
         "Early access to new features and beta tests.", 
      ],
    },
  ];

  return (
    <>
      <UpgradePlanModal active={active} setActive={setActive}  />
      <div className="pricingCardsContainer flex-wrap justify-content-center gap-3">
        {CardData.map((card) => (
          <PricingCard
            key={card.id}
            active={active}
            dollarYear={card.dollarYear}
            dollarMonth={card.dollarMonth}
            badge={card.badge}
            year={card.year}
            priceId={card.priceId}
            month={card.month}
            features={card.features}
          />
        ))}
      </div>
    </>
  );
};

export default PricingUpgrade;
