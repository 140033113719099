import React from 'react';
import { createBrowserRouter, RouterProvider, Route, Routes, Outlet } from 'react-router-dom';
import "./App.css";
import Landingpage from "./pages/landingpage";
import PricingPage from "./pages/pricingpage";
import Signup from "./pages/signup.jsx";
import Login from "./pages/login.jsx";
import ForgetPass from "./pages/forgetPass.jsx";
import Verify from "./pages/verify.jsx";
import ChangePass from "./pages/changePass.jsx";
import Dashnavbar from "./components/dashnavbar.jsx";
import Dashboard from "./dashboard/dashboard.jsx";
import Dashboardoutlet from "./dashboard/dashboardoutlet.jsx";
import DashboardMain from "./dashboard/dashboardMain.jsx";
import DashboardChat from "./dashboard/dashboardChat.jsx";
import Settings from "./dashboard/dashboardUser/settings/settings.jsx";
import Profile from "./dashboard/dashboardUser/settings/profile.jsx";
import Account from "./dashboard/dashboardUser/settings/account.jsx";
import Billing from "./dashboard/dashboardUser/billing.jsx";
import AMLPolicy from "./dashboard/amlPolicy.jsx";
import UserManage from "./dashboard/dashboardAdmin/userManage.jsx";
import Feedback from "./dashboard/dashboardAdmin/feedback.jsx";
import Bill from "./dashboard/dashboardUser/settings/bill.jsx";
import UserFeedback from "./dashboard/dashboardAdmin/userFeedback.jsx";
import PrivateRoute from './middleware/privateRoute.js';
import { DashboardProvider } from './components/ContextData.jsx';

import CookiePolicy from './components/pages/cookiepolicy.jsx';
import Terms from './components/pages/privacypolicy.jsx';
import TermsUk from './components/pages/termsuk.jsx';
import About from './components/pages/home/about.jsx';
const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Landingpage />,
  },
  {
    path: "cookie-policy",
    element: <CookiePolicy  />,
  },
  {
    path: "terms-conditions-us",
    element: <Terms  />,
  },
  {
    path: "about-us",
    element: <About  />,
  },
  {
    path: "terms-conditions-uk",
    element: <TermsUk  />,
  },
  
  
  
  
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/change-password",
    element: <ChangePass />,
  },
  {
    path: "/verify",
    element: <Verify />,
  },
  
  {
    path: "/forget-password",
    element:  <ForgetPass />,
  },
  // {
  //   path: "/pricing",
  //   element: <PricingPage />,
  // },
  {
    element: <PrivateRoute />, 
    children: [
      
      {
        path: "/user-manage",
        element: <UserManage />,
      },
      {
        path: "/feedback",
        element: <Feedback />,
      },
      {
        path: "/",
        element: (
          <DashboardProvider>
            <Dashboard />
          </DashboardProvider>
        ),
        children: [
          {
            path: "/",
            element: <Dashboardoutlet />,
            children: [
              {
                path: "/dashboard/:id",
                element: <DashboardMain />,
              },
            ],
          },
          {
            path: "/dashboard-chat",
            element: <DashboardChat />,
          },
          {
            path: "/dashboard-chat2",
            element: <DashboardChat />,
          },
          {
            path: "/dashboard/file/:name",
            element: <AMLPolicy />,
          },
          // {
          //   path: "/sanctionCheck",
          //   element: <AMLPolicy />,
          // },
          // {
          //   path: "/fccTest",
          //   element: <AMLPolicy />,
          // },
          
          {
            path: "/settings",
            element: <Settings />,
            children: [
              {
                path: "profile",
                element: <Profile />,
              },
              {
                path: "account",
                element: <Account />,
              },
              {
                path: "bill",
                element: <Bill />,
              },
              {
                path: "userfeedback",
                element: <UserFeedback />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

const AppLayout = () => {
  return (
    <>
      <RouterProvider router={appRouter} />
    </>
  );
};

export default AppLayout;
