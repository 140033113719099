import React, { useState, useEffect } from "react";
import Buttoon from "../components/buttoon";
import logoo from "../assets/images/home/logoo.svg";
import finalLogo from "../assets/icons/landingLogo.svg";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import cross2 from "../assets/icons/icons8-cross-25.png";
import menu from "../assets/icons/menu.png";
import Offcanvas from "react-bootstrap/Offcanvas";
import { scroller } from "react-scroll";

const Navbar = () => {
  const [show, setShow] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [chatId, setChatId] = useState();
  const location = useLocation();
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const loginChat = localStorage.getItem("chat_id");

    setChatId(loginChat);
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const navigateToHomeSection = (section = null, offset = 0) => {
    setActiveSection(section); // Update the active section
    navigate("/"); // Navigate to home (or your desired path)

    setTimeout(() => {
      if (section) {
        scroller.scrollTo(section, {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
          offset: offset,
        });
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top (Hero section)
      }
    }, 100);
  };

  return (
    <>
      <div className="nav-wrapper">
        <div className="landingpage-nav">
          <div className="container">
            <div className="innerNav v-center justify-content-between">
              <NavLink to="/" className="cursor-pointer">
                <div className="navLogo">
                  <img src={finalLogo} alt="" />
                </div>
              </NavLink>

              <div className="navContent">
                <ul className="v-center h-center gray-text fw-bold text-med">
                  <li>
                    <NavLink
                      to="/"
                      className={({ isActive }) =>
                        isActive ? "text-primary" : "text-dark"
                      }
                    >
                      Product
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/about-us"
                      className={({ isActive }) =>
                        isActive ? "text-primary" : "text-dark"
                      }
                    >
                      About
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="#"
                      className={() =>
                        activeSection === "feature"
                          ? "text-primary"
                          : "text-black"
                      }
                      onClick={() => navigateToHomeSection("feature", -100)}
                    >
                      Features
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="#"
                      className={() =>
                        activeSection === "resources"
                          ? "text-primary"
                          : "text-black"
                      }
                      onClick={() => navigateToHomeSection("resources", -100)}
                    >
                      Resources
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="#"
                      className={() =>
                        activeSection === "faq" ? "text-primary" : "text-black"
                      }
                      onClick={() => navigateToHomeSection("faq", -100)}
                    >
                      FAQ’s
                    </NavLink>
                  </li>
                </ul>
              </div>

              <div className="navBtns">
                {isLoggedIn ? (
                  <Link to={`/dashboard/${chatId}`}>
                    <Buttoon
                      className="btn btn-primary"
                      minWidth={68}
                      minHeight={39}
                      text="Dashboard"
                    />
                  </Link>
                ) : (
                  <>
                    <Link to="/login">
                      <Buttoon
                        className="btn btn-secondary"
                        minWidth={68}
                        minHeight={39}
                        text="Sign In"
                      />
                    </Link>
                    <Link to="/signup">
                      <Buttoon
                        className="btn btn-primary"
                        minWidth={68}
                        minHeight={39}
                        text="Sign Up"
                      />
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Navbar */}
      <div className="mobile-navbar">
        <NavLink className="d-flex" to="/">
          <img className="mobileLogo" src={finalLogo} alt="" />
        </NavLink>
        <button onClick={handleShow} className="menu-btn">
          <img src={menu} alt="Menu" />
        </button>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <NavLink to="/">
                <img src={finalLogo} alt="Logo" className="logostyle" />
              </NavLink>
            </Offcanvas.Title>
            <img onClick={handleClose} src={cross2} alt="Close" />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="p-0 gray-text fw-bold text-med">
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? "text-primary" : "text-dark"
                  }
                >
                  Product
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about-us"
                  className={({ isActive }) =>
                    isActive ? "text-primary" : "text-dark"
                  }
                >
                  About
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="#"
                  className={() =>
                    activeSection === "feature" ? "text-primary" : "text-black"
                  }
                  onClick={() => navigateToHomeSection("feature", -100)}
                >
                  Features
                </NavLink>
              </li>
              <li>
                <li>
                  <NavLink
                    to="#"
                    className={() =>
                      activeSection === "resources"
                        ? "text-primary"
                        : "text-black"
                    }
                    onClick={() => navigateToHomeSection("resources", -100)}
                  >
                    Resources
                  </NavLink>
                </li>
              </li>
              {/* <li>
                <NavLink to="/pricing">Pricing</NavLink>
              </li> */}
              <li>
                <NavLink
                  to="#"
                  className={() =>
                    activeSection === "faq" ? "text-primary" : "text-black"
                  }
                  onClick={() => navigateToHomeSection("faq", -100)}
                >
                  FAQ’s
                </NavLink>
              </li>
            </ul>

            <div className="navBtns">
              {isLoggedIn ? (
                chatId ? (
                  <Link to={`/dashboard/${chatId}`}>
                    <Buttoon
                      className="btn btn-primary"
                      minWidth={68}
                      minHeight={39}
                      text="Dashboard"
                    />
                  </Link>
                ) : (
                  <span>Loading...</span>
                )
              ) : (
                <>
                  <Link to="/login">
                    <Buttoon
                      className="btn btn-secondary"
                      minWidth={68}
                      minHeight={39}
                      text="Sign In"
                    />
                  </Link>
                  <Link to="/signup">
                    <Buttoon
                      className="btn btn-primary"
                      minWidth={68}
                      minHeight={39}
                      text="Sign Up"
                    />
                  </Link>
                </>
              )}
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default Navbar;
