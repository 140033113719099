import React from "react";

const Heading = (props) => {
  return (
    <div className={`headingContainer ${props.containerClass}`}>
      <h1 className={`text-center fw-bold ${props.headingClass}`}>
        {props.headingtext}
      </h1>
      <p className={`text-large fw-medium text-center ${props.paraClass}`}>
        {props.paraText}
      </p>
    </div>
  );
};

export default Heading;