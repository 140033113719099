import React, { useEffect } from 'react';
import Navbar from '../../shared/navbar';
import Footer from '../../shared/footer';

const CookiePolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div>
      <Navbar />

      <div className="container py-5">
        <div className="w-75 mx-auto mb-4  d-flex flex-column ">
          <h1 className="text-center mb-3">Cookie Policy</h1>
          {/* <p className="text-center mb-3 fs-4"><strong>Effective Date:</strong> Updated 30 October 2024</p> */}
          <p className="text-center mb-4 text-large">
            This Cookie Policy explains how Oganiru Advisory Ltd (“we,” “us,” or “our”) uses cookies and similar tracking 
            technologies on ChatKYC (“Platform”). By using our Platform, you consent to the use of cookies as described in this policy.
          </p>
        </div>

        <h2 className=" mb-2">1. What Are Cookies?</h2>
        <p className="mb-4 text-large">
          Cookies are small text files that are placed on your device (computer, smartphone, tablet, and any other device that can 
          connect to the internet) when you visit a website. They are widely used to make websites work efficiently, enhance user 
          experience, and provide information to the site owners.
        </p>

        <h2 className=" mb-3">2. Types of Cookies We Use</h2>

        <h3 className="h4 mb-2">a. Essential Cookies</h3>
        <ul className="mb-4">
          <li className='text-large'><strong>Purpose:</strong> These cookies are necessary for the operation of our Platform. They enable you to navigate our Platform and use its features.</li>
          <li className='text-large'><strong>Duration:</strong> These are typically session cookies that expire once you close your browser.</li>
        </ul>

        <h3 className="h4 mb-2">b. Performance Cookies</h3>
        <ul className="mb-4">
          <li className='fs-5'><strong>Purpose:</strong> These cookies are necessary for the operation of our Platform. They enable you to navigate our Platform and use its features.</li>
          <li className='fs-5' ><strong>Purpose:</strong> These cookies collect information about how you use ChatKYC, such as pages visited and errors encountered. This helps us improve the performance and functionality of our Platform.</li>
          <li className='fs-5'><strong>Duration:</strong> These cookies may be persistent or session cookies.</li>
        </ul>

        <h3 className="h4 mb-2">c. Functionality Cookies</h3>
        <ul className="mb-4">
          <li className='text-large'><strong>Purpose:</strong> These cookies allow ChatKYC to remember choices you make (such as your language preferences) and provide enhanced, more personalized features.</li>
          <li className='text-large'><strong>Duration:</strong> These cookies are usually persistent and remain on your device after you close your browser.</li>
        </ul>

        <h3 className="h4 mb-2">d. Analytics Cookies</h3>
        <ul className="mb-4">
          <li className='text-large'><strong>Purpose:</strong> We use analytics cookies to understand how users engage with our Platform and identify areas for improvement. These cookies provide us with aggregated statistical information.</li>
          <li className='text-large'><strong>Duration:</strong> These cookies are typically persistent and may remain on your device until deleted.</li>
        </ul>

        <h3 className="h4 mb-2">e. Third-Party Cookies</h3>
        <ul className="mb-4">dddddddd
          <li className='text-large'><strong>Purpose:</strong> Our Platform may include cookies from third-party services that provide additional functionalities, such as payment processing. These third-party cookies are governed by the respective privacy policies of those services.</li>
          <li className='text-large'><strong>Duration:</strong> These cookies can be session or persistent cookies.</li>
        </ul>

        <h2 className=" mb-3">3. Managing Cookies</h2>
        <p className="mb-4 text-large">
          You have the right to accept or reject cookies. Most web browsers allow you to manage your cookie preferences through their 
          settings. You can set your browser to refuse cookies, delete cookies, or alert you when cookies are being sent. However, 
          please note that if you choose to disable cookies, some parts of our Platform may not function properly.
        </p>
        <p className="mb-3"><strong>How to Manage Cookies in Your Browser:</strong></p>
        <ul className="mb-4" >
          <li className='pb-2 text-large'><strong>Google Chrome:</strong> Manage Cookies in Chrome</li>
          <li className='pb-2 text-large'><strong>Mozilla Firefox:</strong> Manage Cookies in Firefox</li>
          <li className='pb-2 text-large'><strong>Microsoft Edge:</strong> Manage Cookies in Edge</li>
          <li className='pb-2 text-large'><strong>Safari:</strong> Manage Cookies in Safari</li>
        </ul>

        <h2 className=" mb-3">4. Cookie Consent</h2>
        <p className="mb-4 text-large">
          Upon your first visit to ChatKYC, you will be presented with a cookie consent banner. You can choose to accept or reject 
          non-essential cookies. Your consent will be recorded, and you can change your preferences at any time by adjusting your 
          browser settings or revisiting our cookie banner.
        </p>

        <h2 className=" mb-3">5. Changes to This Cookie Policy</h2>
        <p className="mb-4 text-large">
          We may update this Cookie Policy from time to time to reflect changes in technology, legislation, or our operations. We will 
          notify you of any significant changes by posting the new policy on our Platform and updating the effective date.
        </p>

        <h2 className=" mb-3">6. Contact Us</h2>
        <p className="mb-4 text-large">
          If you have any questions or concerns about our use of cookies or this Cookie Policy, please contact us at {""}
          <a href="mailto:info@chatkyc.ai">info@chatkyc.ai</a>.
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default CookiePolicy;
