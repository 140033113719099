import React, { useState, useEffect } from "react";
import { Space, Table, Spin } from "antd";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
// import arrowBlack from "../../assets/icons/arrow-black.svg";
import { IoArrowBackSharp } from "react-icons/io5";
import searchIcon from "../../assets/icons/search.svg";
import redTrash from "../../assets/icons/redTrash.svg";
import bluerUser from "../../assets/icons/blueUser.svg";
import ViewInfo from "../modals/viewInfo";
import DelCardModal from "../modals/delCardModal";
import verifyAdmin from "../../middleware/verifyAdmin";
import axios from "axios";

const { Column } = Table;

const UserManage = () => {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isInfoModal, setIsInfoModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [User, setUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState(""); // State for search text

  const showViewInfoModal = async (id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin-route/userinfo/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setSelectedUser(response.data); // Set user data
      setIsInfoModal(true); // Open modal
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  const showDelModal = async (id) => {
    const token = localStorage.getItem("token");
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/admin-route/delete/${id}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          Swal.fire("Deleted!", "The user has been deleted.", "success");

          fetchAllUsers();
        } catch (error) {
          Swal.fire(
            "Error!",
            "An error occurred while deleting the user.",
            "error"
          );
          console.error(error);
        }
      }
    });
  };

  const fetchAllUsers = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin-route/getuser`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data && response.data.data) {
        const formattedUsers = response.data.data.map((user, index) => ({
          key: index.toString(),
          userId: user._id,
          userName: user.username,
          email: user.email,
          status: user.status,
          subscript: user.subscription,
        }));
        setUser(formattedUsers);
        setFilteredUsers(formattedUsers); // Initially set the filtered users as all users
      } else {
        console.error(
          "Data not found in response. Response structure:",
          response.data
        );
      }
    } catch (error) {
      if (error.response) {
        console.error("Error Response Headers:", error.response.headers);
      } else if (error.request) {
        console.error("Error Request:", error.request);
      } else {
        console.error("Error Message:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const checkAdmin = async () => {
      const result = await verifyAdmin();
      if (!result) {
        Swal.fire({
          // icon: "error",
          title: "Access Denied",
          text: "Only admins can access this page.",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/settings/profile");
        });
      } else {
        setIsAdmin(true);
        fetchAllUsers();
      }
    };
    checkAdmin();
  }, [navigate]);

  // Handle the search functionality
  const handleSearch = (e) => {
    setSearchText(e.target.value);
    if (e.target.value === "") {
      setFilteredUsers(User); // If search is empty, show all users
    } else {
      const filteredData = User.filter(
        (user) =>
          user.userName.toLowerCase().includes(e.target.value.toLowerCase()) || // Search by user name
          user.email.toLowerCase().includes(e.target.value.toLowerCase()) // Search by email
      );
      setFilteredUsers(filteredData);
    }
  };

  if (!isAdmin) {
    return null;
  }

  if (loading) {
    return (
      <div
        className="loading-container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" tip="Loading users..." />
      </div>
    );
  }

  return (
    <>
      <div className="userManagementWrapper">
        <div className="user-header d-flex  gap-3">
          <div className="cursor-pointer d-flex justify-content-center align-items-center"   onClick={() => {
              navigate("/settings/profile");
            }}>
          <IoArrowBackSharp  className="fs-3"
          />

          </div>
       
          {/* <img
            className="cursor-pointer"
            onClick={() => {
              navigate("/settings/profile");
            }}
            src={arrowBlack}
            alt=""
          /> */}
          <h4 className="field-text fw-bold">User Management</h4>
        </div>
        <div className="userContent">
          <div className="userSearchField">
            <img src={searchIcon} alt="" />
            <input
              className="w-100 text-med"
              type="text"
              placeholder="Search by name or email"
              value={searchText}
              onChange={handleSearch}
              style={{backgroundColor : "transparent"}} // Call the search handler
            />
          </div>
          <div className="tableWrapper">
            <Table
              dataSource={filteredUsers} // Use filtered users here
              pagination={{
                pageSize: 6,
                showSizeChanger: false,
                itemRender: (page, type, originalElement) => {
                  if (type === "prev") {
                    return <LeftOutlined />;
                  }
                  if (type === "next") {
                    return <RightOutlined />;
                  }
                  if (type === "page") {
                    return <span>{page}</span>;
                  }
                  return originalElement;
                },
                position: ["bottomCenter"],
              }}
              style={{ marginTop: "20px" }}
            >
              <Column title="User ID" dataIndex="userId" key="userId" />
              <Column title="User Name" dataIndex="userName" key="userName" />
              <Column title="Email" dataIndex="email" key="email" />
              <Column
                title="Status"
                dataIndex="status"
                key="status"
                render={(status) => (
                  <span
                    style={{
                      backgroundColor:
                        status === "active"
                          ? "rgba(25, 212, 66, 0.18)"
                          : "rgba(181, 14, 14, 0.18)",
                      borderRadius: "27px",
                      fontWeight: "500",
                      padding: "5px 10px",
                    }}
                  >
                    {status
                      ? status.charAt(0).toUpperCase() + status.slice(1)
                      : "Unknown"}
                  </span>
                )}
              />
              <Column
                title="Subscription"
                dataIndex="subscript"
                key="subscript"
                render={(billingCycle) =>
                  billingCycle
                    ? billingCycle.charAt(0).toUpperCase() +
                      billingCycle.slice(1) +
                      ""
                    : "N/A"
                }
              />
              <Column
                title="Actions"
                key="actions"
                render={(text, record) => (
                  <Space size="middle">
                    <img
                      src={redTrash}
                      alt="Delete"
                      style={{ cursor: "pointer" }}
                      onClick={() => showDelModal(record.userId)}
                    />
                    <img
                      src={bluerUser}
                      alt="User"
                      style={{ cursor: "pointer" }}
                      onClick={() => showViewInfoModal(record.userId)} // Pass user ID
                      />
                  </Space>
                )}
              />
            </Table>
          </div>
        </div>
      </div>

      <ViewInfo
      isInfoModal={isInfoModal}
      setIsInfoModal={setIsInfoModal}
      userData={selectedUser} 

    />      <DelCardModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export default UserManage;
