import React, { useEffect } from 'react';
import Navbar from "../../../shared/navbar";
import Footer from "../../../shared/footer";
import Heading from "../../../shared/heading";
import gloww from "../../../assets/images/home/benefitGlow.webp";
import featureRightPic from "../../../assets/images/home/feature.png";
// import laptop from "../../../assets/images/home/laptop.jpg";
import macc from "../../../assets/images/home/macbok.webp";

const About = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <div className=" aboutSection">
      <Navbar />
      <div name="hero" className="heroSection  ">
        <div className="topGradLayer"></div>
        <div className="container">
          <div className="inner v-center h-center flex-column">
            <Heading headingtext="What is ChatKYC?" />
            <h6 className="text-center fs-5 ">
              ChatKYC is your Analyst, MLRO, Chief Compliance Officer and more.
            </h6>
            <p className="fs-5 lh-1 text-center ">
              Trained exclusively on regulatory datasets, ChatKYC is an
              intelligent AI assistant built to help you address 1st and 2nd
              line compliance tasks and challenges with greater efficiency and
              creativity. Developed by Compliance professionals and trained on
              the highest quality datasets, ChatKYC empowers you to do more,
              faster.
            </p>
          </div>
        </div>
      </div>
      <div className="aboutSection position-relative">
        <img
          className="position-absolute d-none d-lg-block h-100"
          src={gloww}
          alt=""
          style={{ objectFit: "cover", zIndex: "-1" }}
        />
        <div className="container py-5">
          {/* First Section */}
          <div className="w-75 mx-auto text-center mb-5">
            <p className="fw-normal fs-5 pb-2"></p>
          </div>

          {/* Second Section */}
          <div className="bringingmain-div">
            <div className="d-flex flex-column flex-lg-row align-items-center  gap-4">
              {/* Image */}
              <div className="flex-shrink-0 img-column ">
                <img
                  src={featureRightPic}
                  alt="Feature Illustration"
                  className="img-fluid  "
                  //   style={{ maxWidth: "400px" }}
                />
              </div>
              {/* Text */}
              <div className="md:w-50 w-full ">
                <h2 className="mb-4 ">
                  Bringing Simplicity to Compliance Through AI
                </h2>
                <p className="pb-4 fs-5">
                  In the often siloed and challenging world of compliance,
                  ChatKYC is a beacon of innovation and support. Our co-founder
                  Omasirichukwu Anyanwu, draws from a rich tapestry of
                  experience spanning risk, compliance and entrepreneurship.
                </p>
                <p className="fs-5">
                  "Taking inspiration from ChatGPT, which has been a game
                  changer, we thought, imagine if there was a platform built
                  using the same technology, but tailored exclusively for
                  compliance needs and only trained on regulatory datasets? And
                  then, ChatKYC was born. My compliance experience has involved
                  working in various sectors across diverse jurisdictions.
                  ChatKYC has been developed with this global purview,
                  recognising the interconnectedness of today's corporate world,
                  whilst being able to focus on local regulatory nuances" -
                  Omasirichukwu Anyanwu.
                </p>
              </div>
            </div>
          </div>

          {/* Third Section */}
          <div className="bringingmain-div">
            <div className="d-flex flex-column flex-lg-row align-items-center gap-4">
              {/* Image */}

              {/* Text */}
              <div>
                <h2 className="mb-4">The Future</h2>
                <p className="pb-2 fs-5">
                  With ChatKYC, we've harnessed the power of AI to foster a
                  world where professionals, regardless of their expertise, or
                  the depth of their pockets can capitalize on the power of AI.
                </p>
                <p className="pb-2 fs-5">
                  Our technology is poised to empower you with insights and
                  ready to assist in streamlining tasks that once consumed hours
                  of your day. This isn't just about making compliance easier,
                  it's about changing the narrative around it. In an
                  ever-evolving compliance landscape, we believe that
                  professionals should be given the tools to meet their
                  regulatory obligations and better support the needs of their
                  organizations.
                </p>
              </div>

              <div className="flex-shrink-0 img-column order-md-first order-lg-0">
                <img
                  src={macc}
                  alt="Future Illustration"
                  className="img-fluid rounded"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
