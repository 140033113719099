import React, { useEffect, useState } from "react";
import { Modal, Input, Select, message } from "antd";
import Buttoon from "../../components/buttoon";
import axios from "axios";

const { Option } = Select;

const ViewInfo = ({ isInfoModal, setIsInfoModal, userData }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [lastName, setLastName] = useState("");
  const [about, setAbout] = useState(""); // Replacing role with about
  const [status, setStatus] = useState("inactive");

  // Populate fields when userData changes
  useEffect(() => {
    if (userData) {
      setUsername(userData.user.username || "");
      setEmail(userData.user.email || "");
      setFullName(userData.user.first_name || "");
      setLastName(userData.user.last_name || "");
      setAbout(userData.user.about || ""); // Load about field
      setStatus(userData.payment || "inactive");
    }
  }, [userData]);

  const handleSave = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin-route/update-user-info-and-payment`,
        {
          userId: userData.user._id,
          username:username,
          email:email,
          status:status,
          first_name: fullName,
          last_name:lastName,
          about, // Send about field
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      message.success(response.data.message); // Show success message
      setIsInfoModal(false); // Close the modal
    } catch (error) {
      console.error("Failed to update user info and payment status:", error);
      message.error("Failed to update. Please try again."); // Show error message
    }
  };

  const handleCancel = () => {
    setIsInfoModal(false);
  };

  return (
    <Modal
      open={isInfoModal}
      className="credit-modal"
      centered
      width={890}
      onCancel={handleCancel}
      footer={null}
    >
      <div className="modal-content">
        <div className="pass-modal-header w-100">
          <h4 className="fw-bold">View/Edit Information</h4>
        </div>
        <div className="pass-modal-content">
          <div className="confirm-pass-container w-100 d-flex justify-content-between">
            <div className="c-pass-div h-center flex-column gap-2 mt-4">
              <label className="fw-bold">Username</label>
              <Input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="c-pass-div h-center flex-column gap-2 mt-4">
              <label className="fw-bold">Email Address</label>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="confirm-pass-container w-100 d-flex justify-content-between">
            <div className="c-pass-div h-center flex-column gap-2 mt-4">
              <label className="fw-bold">First Name</label>
              <Input
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
            <div className="c-pass-div h-center flex-column gap-2 mt-4">
              <label className="fw-bold">Last Name</label>
              <Input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div className="confirm-pass-container w-100 d-flex justify-content-between">
            <div className="c-pass-div h-center flex-column gap-2 mt-4">
              <label className="fw-bold">About (Describe Your Role and Sector)</label>
              <Input
                type="text"
                value={about}
                onChange={(e) => setAbout(e.target.value)}
              />
            </div>
            <div className="c-pass-div h-center flex-column gap-2 mt-4">
              <label className="fw-bold">Status</label>
              <Select value={status} onChange={(value) => setStatus(value)}>
                <Option value="active">Active</Option>
                <Option value="inactive">Inactive</Option>
              </Select>
            </div>
          </div>

          <div className="h-center">
            <Buttoon
              className="btn btn-primary mt-5 m-auto"
              text="Save"
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ViewInfo;
